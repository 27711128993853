/* eslint-disable max-len */
import React from 'react';
import {
  Link,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({}) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Resources"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Resources"
      />

      <Section title="Learn More about Accessibility">
        <Paragraph>
          Learn more about accessible product design through these websites,
          blogs and educational materials:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://www.w3.org/TR/WCAG21">
              Web Content Accessibility Guidelines (WCAG) 2.1
            </Link>
            : the specific criteria to test for accessibility compliance.
          </li>
          <li>
            <Link href="https://www.a11yproject.com/">A11y Project</Link>:
            simplified WCAG explanations, lists, blogs, tools and other
            resources.
          </li>
          <li>
            <Link href="https://www.digitala11y.com/">Digital A11y</Link>:
            another source for WCAG explanations, including a blog and
            additional resources.
          </li>
          <li>
            <Link href="https://www.w3.org/WAI/courses/foundations-course/">
              W3C Digital Accessibility Foundations
            </Link>
            : free, online course to learn the basics of accessibility.
          </li>
        </List>
      </Section>

      <Section title="Building Accessible Products">
        <Paragraph>
          The{' '}
          <Link href="/guidelines/accessibility/checklists" isDesignCodeLink>
            Uniform Accessibility Checklist
          </Link>{' '}
          is our quick reference for designing any Hudl interface. The following
          tools are referenced in the checklist and elsewhere:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
              Figma Stark Plugin
            </Link>
            : includes free color contrast and visual simulator tools, plus a
            few other paid features.
          </li>
          <li>
            <Link href="https://www.figma.com/community/plugin/892114953056389734/Text-Resizer---Accessibility-Checker">
              Text Resizer plugin
            </Link>
            : resize text in Figma mockups to ensure nothing breaks.
          </li>
          <li>
            <Link href="https://www.figma.com/file/kBPRlhqT74PfaKbzZIhIfG/Uniform-Annotations?node-id=1%3A20">
              Uniform Annotations Figma Library
            </Link>
            : components to help annotate your mockups for dev handoff,
            including tab order and touch targets.
          </li>
        </List>
      </Section>

      <Section title="Test for Accessibility">
        <Paragraph>
          Once designs have been implemented and are ready for testing, use
          these tools to verify that the interface is accessible:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://storybook.js.org/blog/accessibility-testing-with-storybook/">
              Storybook Accessibility Add-on
            </Link>
            : use the panel to check for accessibility violations (if using
            Storybook).
          </li>
          <li>
            <Link href="https://chrome.google.com/webstore/detail/web-disability-simulator/olioanlbgbpmdlgjnnampnnlohigkjla">
              Web Disability Simulator Chrome plugin
            </Link>
            : view the interface through the lense of someone with a disability.
          </li>
        </List>
      </Section>

      <Section title="Legal Requirements">
        <Paragraph>
          These are some of the legal requirements Hudl should be aware of:
        </Paragraph>
        <List type="unstyled">
          <li>
            <Link href="https://www.access-board.gov/ict/">
              ADA Section 508
            </Link>
            : U.S. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32019L0882">
              European Accessibility Act
            </Link>
            : E.U. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://www.legislation.gov.uk/ukpga/2010/15/contents">
              Equality Act 2010
            </Link>
            : U.K. regulations related to accessibility.
          </li>
          <li>
            <Link href="https://sync.hudlnet.com/display/UNIFORM/2022/01/21/Hudl%2C+Uniform%2C+and+Accessibility">
              Disability Discrimination Act of 1992
            </Link>
            : Australian regulations related to accessibility.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
